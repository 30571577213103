<template>
  <v-container>
    <v-form ref="access-form" @submit.prevent="formSubmit">
      <v-row>
        <v-col cols="12">
          <div class="page-title">{{ $route.meta.title }}</div>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col md="12" cols="12">
                  <h3>管理員</h3>
                </v-col>
                <v-col md="12" cols="12">
                  <FormControl v-model="admin" inputType="checkbox" :options="accessRightList" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col md="12" cols="12">
                  <h3>員工</h3>
                </v-col>
                <v-col md="12" cols="12">
                  <FormControl v-model="staff" inputType="checkbox" :options="accessRightList" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" class="px-6" small depressed :disabled="isFetching" type="submit">儲存</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
 
 <script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl'
import Cookies from 'js-cookie'

export default {
  name: 'AccessDetail',
  components: {
    FormControl,
  },
  data() {
    return {
      admin: ['user_management', 'course_management', 'order_management', 'promotion_management'],
      staff: ['user_management', 'course_management'],
      accessRightList: [
        { value: 'user_management', text: '用戶管理' },
        { value: 'course_management', text: '課程管理' },
        { value: 'order_management', text: '訂單管理' },
        { value: 'promotion_management', text: '宣傳管理' },
      ],
      cookiesKey: 'INN_ACADEMY_CMS_PERMISSION',
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
    id() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)
        const isValid = this.$refs['access-form'].validate()
        if (isValid) {
          const data = {
            staff: this.staff,
            admin: this.admin,
          }

          Cookies.set(this.cookiesKey, JSON.stringify(data), {
            path: '/',
            sameSite: 'Strict',
            secure: true,
          })

          this.setFetching(false)
          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: null,
          })
          this.setShowDialog(true)
        } else {
          this.setFetching(false)
        }
      }
      /*  console.log('save')
      const isValid = this.$refs['access-form'].validate()
      if (isValid) {
        this.setDialogMessage({
          message: '儲存成功',
          isError: false,
          returnLink: this.id === undefined ? { name: 'access' } : null,
        })
        this.setShowDialog(true)
      } */
    },
  },
  created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const cookieData = Cookies.get(this.cookiesKey)
    if (this.$validate.DataValid(cookieData)) {
      const data = JSON.parse(cookieData)
      this.admin = data.admin || ['user_management', 'course_management', 'order_management', 'promotion_management']
      this.staff = data.staff || ['user_management', 'course_management']
    } else {
      this.admin = ['user_management', 'course_management', 'order_management', 'promotion_management']
      this.staff = ['user_management', 'course_management']
    }
    this.$store.dispatch('toggleLoadingPage', false)
  },
}
</script>
 
 <style lang="scss" scoped>
</style>